<template>
	<div id="app" v-if="renderer">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: "App",
		data() {
			return {
				renderer: true
			};
		},
		mounted() {
			window.addEventListener("resize", this.reflushWindow, false);
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.reflushWindow);
		},
		methods: {
			// reflushWindow() {
			//   // 监听窗口变化，刷新当前页面
			//   // location.assign(location.href);
			//   this.renderer = false;
			//   this.$nextTick(()=>{
			//     this.renderer = true;
			//   })
			// },
		},
	};
</script>

<style lang="scss">
	@import url('./screen.css');
	@import url('./common.css');

	#app {
		// min-width: 1200px;
		// height: 150vh;

		::v-deep .el-button--danger {
			background-color: #F32C6C !important;
			border-color: #F32C6C !important;
		}
	}

	[role="dialog"] {
		.el-button--primary {
			background-color: #F32C6C !important;
			border-color: #F32C6C !important;
		}
	}

	[role="dialog"] :hover {
		.el-button--primary {
			background-color: #F32C6C !important;
			border-color: #F32C6C !important;
		}
	}
	

	[class="logo-tips"] {
		font-size: 12px !important;
	}

	.el-pager {
		.active {
			background-color: #F32C6C !important;
		}
	}

	.main-page {
		min-width: 1200px !important;
	}

	[class*="el-checkbox"] {
		transform: scale(1.15) !important;
	}

	[class*="is-checked"] {
		[class~="el-checkbox__inner"] {
			background-color: #F32C6C !important;
			border-color: #F32C6C !important;
		}

	}

	[class*="is-indeterminate"] {
		[class~="el-checkbox__inner"] {
			background-color: #f33c27 !important;
			border-color: #F32C6C !important;
		}
	}

	.el-pager .active {
		background-color: rgb(243, 44, 108) !important;
	}

	.el-dialog__wrapper .el-dialog {
		border-radius: 6px;
	}

	.el-dialog__wrapper .el-dialog__header {
		border-top-left-radius: 6px;
		border-top-right-radius: 6px;
	}
</style>