const directive = {
    inserted:function(el,binding){
        // const page = document.querySelector('.page-content');
        // const app = document.querySelector('#app');
        // app.style.overflow='hidden'
        // console.log(page.clientHeight)
        let parent = '.data-content';
        // let height = 0;
        if(binding.value){
            // height = binding.value;
            parent = binding.value.parent?binding.value.parent:parent
        }
        const elParent = document.querySelector(`${parent}`);
        const parentHeight = elParent.clientHeight;
        console.log(parentHeight);
        el.style.height=`calc(${parentHeight - 40}px)`;
        // el.style.height=`calc(100vh - ${280 + height}px)`;
        el.style.overflow="auto"
    }
}

export default directive