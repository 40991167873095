import {
	handleGetSms,
	handlesignOut
} from '@index/api/commonapi'
import {
	Message
} from 'element-ui'
// import { removeToken } from './auth'
import router from '@index/router';
const common = {
	publicKey: 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAgsB0o5s7TMLchCRStnfDMWaZRUDHR0h7UBeiiMq8nV4Zwon++WtYgaHD7VOppPGeDzXIAg/3xU9WQehwskQupi0QRceaJgqIwjlAQr1bX/48kR9eAlT5mlrdJf3dhBpUSdJJ9LDxDYOSMwWn6eeQPV0YES0aW2akpBeRaEw5IyBoDpSYudrA1PrAY2V30Lconlqnj3Kx00Uiqs5TyyqnD20843s92mE5zpAENrBWU0+sEL5x5+WXXljnfIu8f20sXwzZ3dssaFwDbClj46fpL/5U4ObV7yTaLmZ5O7wI92HSMtCuBFXoobfqM+y0qef3TAQQ34rz5WOE9zw48mD7KQIDAQAB',
	requiredValidate: (data) => {
		if (data) {
			data.forEach((elem) => {
				if (!elem.value) {
					throw elem.tips
				}
			})
		}
	},
	getSmsCode(phone, callback) { // 获取短信验证码
		try { // 验证
			common.requiredValidate([{
				value: phone,
				tips: '电话不能为空!'
			}])
		} catch (e) {
			Message({
				message: e,
				type: 'error',
				duration: 5 * 1000
			})

			return
		}

		handleGetSms(phone).then(res => {
			if (res.status == 200) {
				Message({
					message: '发送成功请注意查收!',
					type: 'success',
					duration: 5 * 1000
				})
				callback(res.data.smsId)
				//   this.form.smsId = res.data.smsId;
			} else {
				Message({
					message: res.msg,
					type: 'error',
					duration: 5 * 1000
				})
			}
		})
	},
	signout() { // 退出
		handlesignOut().then(res => {
			if (res.status == 200) {
				Message({
					message: res.msg,
					type: 'success',
					duration: 5 * 1000
				})

				// 同时移除token
				// removeToken()
				var myHomePage = sessionStorage.getItem('myHomePage');
				var domainInfo = sessionStorage.getItem('domainInfo');
				sessionStorage.clear();
				if(domainInfo){
					sessionStorage.setItem('domainInfo', domainInfo);
				}
				
				if (myHomePage) {
					router.push(myHomePage) // 注意，这里使用了Vue的全局实例
				} else {
					router.push('/home') // 注意，这里使用了Vue的全局实例
				}

			} else {
				Message({
					message: res.msg,
					type: 'error',
					duration: 5 * 1000
				})
			}
		})
	}

}

export default common