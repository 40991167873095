// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!../../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-3!./screen.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--8-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-2!../../../node_modules/postcss-loader/src/index.js??ref--8-oneOf-1-3!./common.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "#app ::v-deep .el-button--danger,[role=dialog] .el-button--primary,[role=dialog] :hover .el-button--primary{background-color:#f32c6c!important;border-color:#f32c6c!important}[class=logo-tips]{font-size:12px!important}.main-page{min-width:1200px!important}[class*=el-checkbox]{transform:scale(1.15)!important}[class*=is-checked] [class~=el-checkbox__inner]{background-color:#f32c6c!important;border-color:#f32c6c!important}[class*=is-indeterminate] [class~=el-checkbox__inner]{background-color:#f33c27!important;border-color:#f32c6c!important}.el-pager .active{background-color:#f32c6c!important}.el-dialog__wrapper .el-dialog{border-radius:6px}.el-dialog__wrapper .el-dialog__header{border-top-left-radius:6px;border-top-right-radius:6px}", ""]);
// Exports
module.exports = exports;
