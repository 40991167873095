import Vue from 'vue';
import modifypassword from './modifypassword.vue';
// 打开设置密码弹窗
export function openSetPasswd() {
    const SetLoginPwd = Vue.extend(modifypassword);
    const vm = new SetLoginPwd().$mount();
    vm.$data.modifyPasswordDialogVisible = true;
    vm.$el.id="password";
    const $el = document.querySelector('#password')
    $el ? $el.remove():''
    document.querySelector('#app').appendChild(vm.$el)
}